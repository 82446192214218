import React, { useState, useEffect } from "react";
import { Table, Pagination } from "react-bootstrap";
import Sidebar from "./Sidebar";
import Navbar from "./Navbar";
import Footer from "./Footer";
import "../assets/Styling/customstyle.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SmallLoader } from "../components/SmallLoader";
import { faHome, faSearch } from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Row,
  Card,
  Nav,
  Form,
  Breadcrumb,
  InputGroup,
  Button,
} from "@themesberg/react-bootstrap";
import moment from "moment";
import { TableSkeleton } from "../pages/TableSkeleton";
import { ButtonLoader } from "./ButtonLoader";
import { showNotification } from "../service/showNotification";
import { MdRefresh } from "react-icons/md";

const ProjectInput = () => {
  const [data, setData] = useState([]);
  const [showSidebar, setShowSidebar] = useState(true);
  const [loading, setloading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalDocs, setTotalDocs] = useState(0);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isIndex, setIsIndex] = useState("");
  const [disable, setDisable] = useState(false);

  const handleSearch = (e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      setSearchTerm(value);
      setError("");
    }
  };

  const filteredData = data.filter((item) =>
    item.zohoCustomerId.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const FormatNumber = (number) => {
    return `${number.toString()}%`;
  };

  const FormatNumber1 = (number) => {
    return `$${number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
  };

  const handleResize = () => {
    if (window.innerWidth <= 763) {
      setShowSidebar(true);
    } else {
      setShowSidebar(true);
    }
  };

  const totalInputFeilds = data?.length;
  const itemsPerPage = 10;
  const totalPages = Math.ceil(totalDocs / itemsPerPage);
  const showPaginationNumbers = totalPages > 10;

  useEffect(() => {
    const fetchData = async () => {
      try {
        setloading(true);
        const token = localStorage.getItem("token");
        const response = await fetch(
          `https://api.inceptionfinancial.app/api/project-input?page=${currentPage}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (!response || !response.body) {
          throw new Error("No response");
        }
        const jsonData = await response.json();
        setData(jsonData.data.data);
        setTotalDocs(jsonData.data.totalDocs);
        setloading(false);
      } catch (error) {
        setloading(false);
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [currentPage]);

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleProcess = async (item, index) => {
    try {
      setIsLoading(true);
      setIsIndex(index);
      setDisable(true);
      const token = localStorage.getItem("token");
      const response = await fetch(
        `https://api.inceptionfinancial.app/api/project-input`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(item),
        }
      );
      if (!response.ok) {
        throw new Error("Failed to process item");
      }
      const jsonData = await response.json();
      if (jsonData) {
        showNotification("success", jsonData?.message);
      }
      let isDuplicate = data;
      setData((prevData) => {
        // const newData = [...prevData];
        // if (jsonData.status === 200)
        //   newData.splice(index, 1, { ...prevData[index], isProcessed: true });
        // return newData;
        if (jsonData.status === 200) {
          isDuplicate = filteredData?.map((dataItem) => {
            if (dataItem.trancheName === item.trancheName && dataItem.zohoCustomerId === item.zohoCustomerId)
              return { ...dataItem, isProcessed: true }
            return dataItem
          });
        }
        return isDuplicate;
      });


      setIsLoading(false);
      setDisable(false);
    } catch (error) {
      console.error("Error processing item:", error);
      setIsLoading(false);
      setDisable(false);
    }
  };
  return (
    <>
      {showSidebar && <Sidebar />}
      <div className={showSidebar ? "component" : "component-full-width"}>
        <div className="mx-3">
          <Navbar />
        </div>

        <div className="d-xl-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4 mx-3">
          <div className="d-block mb-4 mb-xl-0">
            <Breadcrumb
              className="d-none d-md-inline-block "
              listProps={{
                className: "breadcrumb-dark breadcrumb-transparent ",
              }}
            >
              <Breadcrumb.Item>
                <FontAwesomeIcon icon={faHome} />
              </Breadcrumb.Item>
              <Breadcrumb.Item active>Project Input</Breadcrumb.Item>
            </Breadcrumb>
            <h4>Project Input</h4>
          </div>

          <div className=" search-container">
            <InputGroup>
              <InputGroup.Text>
                <FontAwesomeIcon icon={faSearch} />
              </InputGroup.Text>
              <Form.Control
                type="text"
                value={searchTerm}
                placeholder="Search Zoho Customer ID..."
                isInvalid={!!error}
                onChange={handleSearch}
              />
            </InputGroup>
            <Form.Control.Feedback type="invalid">
              {error}
            </Form.Control.Feedback>
          </div>
        </div>

        {loading ? (
          <TableSkeleton />
        ) : (
          <div className="scrollable-table-container2 mx-3">
            <Card
              border="light"
              className="table-wrapper table-responsive shadow-sm"
            >
              <Card.Body className="pt-0">
                <Table hover className="user-table align-items-center">
                  <thead>
                    <tr>
                      <th>S.No</th>
                      <th>Tranche Name</th>
                      <th>Zoho Customer ID</th>
                      <th>Deal Name</th>
                      <th>Deal Type</th>
                      <th>Size [KW]</th>
                      <th>Year 1KWH</th>
                      <th>Epc Price</th>
                      <th>Inverter Warranty</th>
                      <th>Host Deposit</th>
                      <th>Inception Purchase Price</th>
                      <th>Inception Equity Contribution</th>
                      <th>Post Year 6 Admin Fee</th>
                      <th>ITC [%]</th>
                      <th>Project State</th>
                      <th>unique Run ID</th>
                      <th>CREATED ON</th>
                      <th>Status</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredData?.length <= 0 ? (
                      <tr>
                        <td className="text-center bg-grey" colSpan={100}>
                          No Records Found.
                        </td>
                      </tr>
                    ) : (
                      filteredData.map((item, index) => {
                        return (
                          <tr key={item._id}>
                            <td>{index + 1}.</td>
                            {item.trancheName ? (
                              <td className="text-center">
                                {item.trancheName}
                              </td>
                            ) : (
                              <td className="text-center">-</td>
                            )}
                            <td>{item.zohoCustomerId}</td>
                            <td>{item.dealName}</td>
                            <td>{item.dealType}</td>
                            <td>{item.sizeKW}</td>
                            <td>{item.year1KWH}</td>
                            <td>{item.epcPrice}</td>
                            <td className="text-center">
                              {item.inverterWarranty}
                            </td>
                            <td>{FormatNumber1(item.hostDeposit)}</td>
                            <td>
                              {FormatNumber1(item.inceptionPurchasePrice)}
                            </td>
                            <td>{item.inceptionEquityContribution}</td>
                            <td>{item.post6YearAdminFee}</td>
                            <td>{FormatNumber(item.itcInPercent)}</td>
                            <td>{item.projectState}</td>
                            <td>{item.uniqueRunId}</td>
                            <td className="flex align-center jsutify-center flex-col ">
                              <span className="fw-normal">
                                {moment(item.updatedAt).format("lll")}
                              </span>
                            </td>
                            {item.isProcessed ? (
                              <td>
                                <p className="status-btn">Processed</p>
                              </td>
                            ) : (
                              <td>
                                <button className="status-btn2 w-100">
                                  {" "}
                                  Not Processed
                                </button>
                              </td>
                            )}
                            {!item.isProcessed ? (
                              <td>
                                <Button
                                  className={`btn align-center text-center w-100 `}
                                  style={{
                                    height: "42px",
                                    borderRadius: "8px",
                                    backgroundColor: "rgba(6, 1, 61, 0.8)",
                                  }}
                                  onClick={() => handleProcess(item, index)}
                                >
                                  <MdRefresh
                                    style={{ fontSize: "1.3rem" }}
                                    className={
                                      isLoading && isIndex === index
                                        ? "rotate"
                                        : ""
                                    }
                                  />
                                </Button>
                              </td>
                            ) : (
                              <td>
                              </td>
                            )}
                          </tr>
                        );
                      })
                    )}
                  </tbody>
                </Table>
                <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
                  <Nav>
                    <Pagination className="mb-2 mb-lg-0">
                      <Pagination.Prev
                        onClick={() =>
                          setCurrentPage((prev) => Math.max(prev - 1, 1))
                        }
                        disabled={currentPage === 1}
                      >
                        Previous
                      </Pagination.Prev>
                      {!showPaginationNumbers &&
                        [...Array(totalPages)].map((_, page) => (
                          <Pagination.Item
                            key={page + 1}
                            active={page + 1 === currentPage}
                            onClick={() => setCurrentPage(page + 1)}
                          >
                            {page + 1}
                          </Pagination.Item>
                        ))}
                      {showPaginationNumbers && (
                        <>
                          {currentPage > 4 && <Pagination.Ellipsis disabled />}
                          {[...Array(totalPages)].map((_, page) => {
                            if (
                              (page === 0 && currentPage < 5) ||
                              (page === totalPages - 1 &&
                                currentPage > totalPages - 5) ||
                              (page > currentPage - 3 &&
                                page < currentPage + 2) ||
                              (currentPage === totalPages && page < 3)
                            ) {
                              return (
                                <Pagination.Item
                                  key={page + 1}
                                  active={page + 1 === currentPage}
                                  onClick={() => setCurrentPage(page + 1)}
                                >
                                  {page + 1}
                                </Pagination.Item>
                              );
                            }
                            return null;
                          })}
                          {currentPage < totalPages - 3 && (
                            <Pagination.Ellipsis disabled />
                          )}
                          {currentPage !== totalPages && (
                            <Pagination.Item
                              onClick={() => setCurrentPage(totalPages)}
                            >
                              {totalPages}
                            </Pagination.Item>
                          )}
                        </>
                      )}
                      {totalDocs ? (
                        <Pagination.Next
                          onClick={() =>
                            setCurrentPage((prev) =>
                              Math.min(
                                prev + 1,
                                Math.ceil(totalDocs / itemsPerPage)
                              )
                            )
                          }
                          disabled={currentPage === totalPages}
                        >
                          Next
                        </Pagination.Next>
                      ) : (
                        <Pagination.Next
                          onClick={() =>
                            setCurrentPage((prev) =>
                              Math.min(
                                prev + 1,
                                Math.ceil(totalDocs / itemsPerPage)
                              )
                            )
                          }
                        >
                          Next
                        </Pagination.Next>
                      )}
                    </Pagination>
                  </Nav>

                  <small className="fw-bold">
                    Showing <b>{totalInputFeilds}</b> out of <b>{totalDocs}</b>{" "}
                    entries
                  </small>
                </Card.Footer>
              </Card.Body>
            </Card>
          </div>
        )}
        <div className="footer">
          <Footer />
        </div>
      </div>
    </>
  );
};
export default ProjectInput;