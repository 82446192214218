import React, { useState } from "react";
import { MdDownload } from "react-icons/md";
import { GrAttachment } from "react-icons/gr";
import { IoMdSend, IoMdDownload } from "react-icons/io";
import { IoClose } from "react-icons/io5";
import { ButtonLoader } from "./ButtonLoader";
import {
  downloadLicence,
  updateEntityComment,
  uploadFile,
} from "../service/api";
import "../assets/Styling/EntityPopup.css";
import { showNotification } from "../service/showNotification";

export const EntityTablePopup = ({
  setPopup,
  data,
  downloadLoader,
  downloadAttachment,
  setReload,
}) => {
  const onClose = () => {
    setPopup(false);
  };

  const [chat, setChat] = useState(data?.chat || []);
  const [attachmentId, setAttachmentId] = useState("");
  const [isAttachment, setIsAttachment] = useState(false);
  const [isLoading, setIsLoding] = useState(false);
  const [message, setMessage] = useState("");
  const [file, setFile] = useState(null);

  const onAttachmentClose = () => {
    setIsAttachment(false);
  };

  const setUploadedFile = (e) => {
    e.stopPropagation();
    const file = e.target.files[0];
    console.log(file, "file");
    setIsAttachment(true);
    setFile(file);
  };

  const getCurrentDateTime = () => {
    const date = new Date();

    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    return `${month}-${day}-${year} ${hours}:${minutes}`;
  };

  const addChat = async (zohoId) => {
    if (!file && !message) return;
    let id = "";
    if (file) {
      try {
        setIsLoding(true);
        const formData = new FormData();
        formData.append("file", file);
        formData.append("contactId", zohoId);

        const response = await uploadFile(formData);
        id = response.data.data.details.id;
        console.log(response.data.data.details.id, "response new data");
        setAttachmentId(id);
        setIsAttachment(false);
        setIsLoding(false);
      } catch (error) {
        console.log(error, "error");
      }
    }

    const data = {
      message: message,
      attachmentId: id,
      replyTime: await getCurrentDateTime(),
      senderId: "", // taken from backend
      zohoId: zohoId,
    };
    const newChat = [...chat];
    newChat.push(data);
    setChat(newChat);
    setIsAttachment(false);
    try {
      const response = await updateEntityComment(data);
      setMessage("");
      setReload((prev) => !prev);
    } catch (error) {
      console.log("error", error);
    }
  };

  const downloadAttachments = async (e, fileId, zohoId) => {
    e.stopPropagation();
    try {
      if (!fileId) {
        console.log("No ID found");
        return;
      }
      const response = await downloadLicence({ fileId, zohoId });
      console.log("res", response);
      if (!response.data) {
        throw new Error("File not downloaded");
      }
      const blob = response.data;
      const url = window.URL.createObjectURL(blob);
      console.log(url, "URL");
      const a = document.createElement("a");
      a.href = url;
      a.download = "Attachment";
      a.style.display = "none";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.log(error);
    }
  };

  console.log(data, "ddd");
  return (
    <>
      <div className="overlay" onClick={() => onClose()}>
        <div className="overlayContent" onClick={(e) => e.stopPropagation()}>
          <span className="closeBtn" onClick={() => onClose()}>
            &times;
          </span>
          <div className="titleBox">
            <div className="labels">Email</div>
            <div className="inputs">{data?.email}</div>
          </div>
          <div className="titleBox">
            <div className="labels">
              Corporation or Limited Liability Company?
            </div>
            <div className="inputs">
              {data?.isCorporation
                ? "Corporation"
                : "Limited Liability Company"}
            </div>
          </div>
          <div className="titleBox">
            <div className="labels">
              What is the primary type of business (i.e. Construction Company,
              Clothing Store, Holding Company, etc.)
            </div>
            <div className="inputs">{data?.businessType || "na"}</div>
          </div>
          <div className="titleBox">
            <div className="labels">
              What State would you like the company formed?
            </div>
            <div className="inputs">{data?.companyBuiltState || "na"}</div>
          </div>
          <div className="titleBox">
            <div className="labels">
              Would you like us to "Qualify" or "Register" your new company to
              do business in any other states? If so, where?
            </div>
            <div className="inputs">
              {data?.qualityRegisterNewCompany || "na"}
            </div>
          </div>
          <div className="titleBox">
            <div className="labels">Potential Company Name 1</div>
            <div className="inputs">{data?.potentialCompanyName1 || "na"}</div>
          </div>
          <div className="titleBox">
            <div className="labels">Potential Company Name 2</div>
            <div className="inputs">{data?.potentialCompanyName2 || "na"}</div>
          </div>
          <div className="titleBox">
            <div className="labels">Potential Company Name 3</div>
            <div className="inputs">{data?.potentialCompanyName3 || "na"}</div>
          </div>
          <div className="titleBox">
            <div className="labels">Mailing Address of New Company</div>
            <div className="inputs">{data?.companyMailingAddress || "na"}</div>
          </div>
          <div className="titleBox">
            <div className="labels">
              Please provide your Social Security Number (For the Company
              Employer Identification Application - EIN).
            </div>
            <div className="inputs">{data?.socialSecurityNumber || "na"}</div>
          </div>
          <div className="titleBox">
            <div className="labels">Do you require a Registered Agent?</div>
            <div className="inputs">
              {data?.registerAgentRequired ? "Yes" : "No"}
            </div>
          </div>
          {/* {data?.registerAgentRequired ? */}
          <div className="titleBox">
            <div className="labels">
              Are you okay paying a $125/year fee for us to contract an agent?
            </div>
            <div className="inputs">
              {data?.isContractAgentFee ? "Yes" : "-"}
            </div>
          </div>
          {/* : */}
          <div className="titleBox">
            <div className="labels">
              Who will act as your registered agent? (Full Legal Name)
            </div>
            <div className="inputs">{data?.registerAgentFullName || "na"}</div>
          </div>
          <div className="titleBox">
            <div className="labels">
              Legal name of all Members, Managers, Shareholders
            </div>
            <div className="inputs">{data?.membersLegalName || "na"}</div>
          </div>
          {/* } */}
          {/* <div className='titleBox'>
                        <div className='labels'>
                            Legal name of all Members, Managers, Shareholders
                        </div>
                        <div className='inputs'>
                            {data?.membersLegalName || "na"}
                        </div>
                    </div> */}
          <div className="titleBox">
            <div className="labels">Driver License</div>
            <div
              onClick={(e) =>
                downloadAttachment(
                  e,
                  data.driverLicence,
                  data.zohoCustomerId,
                  -1
                )
              }
              className="inputs w-25 rounded border border-black"
              style={{ height: "40px" }}
            >
              {downloadLoader ? (
                <div className="h-100 text-center pt-2">
                  <ButtonLoader show={true} />
                </div>
              ) : (
                <div className="p-2">
                  <MdDownload className="fs-4" />
                  <span className="p-2">Download</span>
                </div>
              )}
            </div>
          </div>
          <div className="titleBox">
            <div className="labels">Reply</div>
            <div className="outerCover">
              <div className="scrollCover">
                {chat
                  // .slice(0)
                  // .reverse()
                  .map((item, index) => {
                    return (
                      <div
                        key={index}
                        className={` ${
                          item.senderId == "65b8e19e5151f421bbb371cc"
                            ? "innerBoxAdmin"
                            : "innerBox"
                        } `}
                      >
                        {item.message}
                        {item.attachmentId && (
                          <div
                            onClick={(e) =>
                              downloadAttachments(
                                e,
                                item.attachmentId || "",
                                data?.zohoCustomerId || ""
                              )
                            }
                            className="attachmentBox "
                          >
                            <span className="attachmentText">ATTACHMENT</span>
                            <IoMdDownload />
                          </div>
                        )}
                        <div className="replyTime">{item.replyTime}</div>
                      </div>
                    );
                  })}
              </div>
              {isAttachment && isLoading && (
                <div className="attachmentTextBox">
                  <div>SENDING ...</div>
                </div>
              )}
              {isAttachment && !isLoading && (
                <div className="attachmentTextBox">
                  <IoClose
                    className="attachmentCloseBtn"
                    onClick={onAttachmentClose}
                  />
                  <div className="attachText">{file?.name || ""}</div>
                </div>
              )}
              <div className="inputArea">
                <input
                  type="file"
                  className=""
                  id="fileUpload"
                  onChange={setUploadedFile}
                  hidden
                />
                <label htmlFor="fileUpload">
                  <GrAttachment className="pin" />
                </label>
                <input
                  type="text"
                  className="input"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                />
                <IoMdSend
                  className="send"
                  onClick={() => addChat(data?.zohoCustomerId || "")}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
