import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Row,
  Form,
  Card,
  Button,
  Container,
  InputGroup,
} from "@themesberg/react-bootstrap";
import { Link } from "react-router-dom";
import { Routes } from "../../routes";
import { forgotPasswordApi } from "../../service/api";
import '../../assets/Styling/customstyle.css'
export default () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [err, setErr] = useState("");
  const handleSubmit = async () => {
    try {
      const body = {
        email: email,
      };
      const resp = await forgotPasswordApi(body);
      if (resp?.data.success) {
        setMessage(resp.data.message);
        setErr("");
      } else {
        setErr(resp.data.error);
        setMessage("");
      }
    } catch (error) {
      setErr("Something went wrong. Please try again later.");
      setMessage("");
    }
  };
  return (
    <main>
      <section className="vh-lg-100 mt-4 mt-lg-0 bg-soft d-flex align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col
              xs={12}
              className="d-flex align-items-center justify-content-center"
            >
              <div className="signin-inner bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                <h3 className="mb-4">Forgot your password?</h3>
                <p className="mb-4 text-muted">
                  Don't worry! Just enter your email address and we'll send you a
                  link to reset your password.
                </p>
                <Form>
                  <Form.Group controlId="email" className="mb-4">
                    {message && <span className="text-success mb-3">{message}</span>}
                    {err && <span className="text-danger mb-3">{err}</span>}
                    <Form.Label>Your Email</Form.Label>
                    <InputGroup>
                      <Form.Control
                        type="email"
                        placeholder="john@example.com"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                      />
                    </InputGroup>
                  </Form.Group>
                  <Button
                    variant="primary"
                    type="button"
                    className="w-100"
                    onClick={handleSubmit}
                  >
                    Recover Password
                  </Button>
                  <div className="text-center mt-4">
                    <Card.Link
                      as={Link}
                      to={Routes.Signin.path}
                      className="text-decoration-none text-gray-900 border border-light-grey border-2 rounded p-2"
                    >
                      <FontAwesomeIcon icon={faAngleLeft} className="me-2" />
                      Back to sign in
                    </Card.Link>
                  </div>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};