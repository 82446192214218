import React from "react";
import { useHistory } from "react-router-dom";
import { Routes } from "../routes";
export default function Layout({ children }) {
  const auth = localStorage.getItem("token");
  const history = useHistory();
  if (!auth) {
    history.push(Routes.Signin.path);
  }
  return <div>{children}</div>;
}
