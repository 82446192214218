import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleLeft,
  faEnvelope,
  faUnlockAlt,
} from "@fortawesome/free-solid-svg-icons";
import {
  faFacebookF,
  faGithub,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import {
  Col,
  Row,
  Form,
  Card,
  Button,
  FormCheck,
  Container,
  InputGroup,
} from "@themesberg/react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { Routes } from "../routes";
import BgImage from "../assets/img/illustrations/signin.svg";
import { loginApi } from "../service/api";
import { showNotification } from "../service/showNotification";
import Preloader from "../components/Preloader";
export default () => {
  const [values, setValues] = useState({
    email: "",
    password: "",
  });
  const [err, setErr] = useState("");
  const [isLoading, setLoading] = useState(false);
  const handleChange = (event) => {
    const { name, value } = event.target;
    setValues({
      ...values,
      [name]: value,
    });
  };
  const history = useHistory();
  const handleLogin = async (e) => {
    setLoading(true);
    e.preventDefault();
    try {
      const body = {
        email: values?.email,
        password: values?.password,
      };
      const resp = await loginApi(body);
      const { data } = resp.data;
      setLoading(false);
      if (data) {
        showNotification("success", resp?.data?.message);
        localStorage.setItem("token", data.token ?? "");
        localStorage.setItem("name", data.user.name ?? "");
        history.push(Routes.DashboardOverview.path);
      }
    } catch (error) {
      if (error.response?.status === 401) {
        setErr("Invalid email or password");
        setLoading(false);
      } else {
        setErr("Internal server error");
        setLoading(false);
      }
    }
  };
  return (
    <>
      {isLoading ? (
        <Preloader show={true} />
      ) : (
        <main>
          <section className="d-flex align-items-center my-5 mt-lg-8 mb-lg-5">
            <Container>
              <Row className="justify-content-center form-bg-image" style={{ backgroundImage: `url(${BgImage})` }}>
                <Col
                  xs={12}
                  md={8}
                  lg={6}
                  className="d-flex align-items-center justify-content-center"
                >
                  <Card className="bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100">
                    <div className="text-center text-md-center mb-4 mt-md-0">
                      <h3 className="mb-0">Sign in</h3>
                    </div>
                    <Form className="mt-4" onSubmit={handleLogin}>
                      <Form.Group id="email" className="mb-4">
                        <Form.Label>Your Email</Form.Label>
                        <InputGroup>
                          <InputGroup.Text>
                            <FontAwesomeIcon icon={faEnvelope} />
                          </InputGroup.Text>
                          <Form.Control
                            autoFocus
                            required
                            type="email"
                            placeholder="example@inception.com"
                            name="email"
                            onChange={handleChange}
                          />
                        </InputGroup>
                      </Form.Group>
                      <Form.Group id="password" className="mb-4">
                        <Form.Label>Your Password</Form.Label>
                        <InputGroup>
                          <InputGroup.Text>
                            <FontAwesomeIcon icon={faUnlockAlt} />
                          </InputGroup.Text>
                          <Form.Control
                            required
                            type="password"
                            placeholder="Password"
                            name="password"
                            onChange={handleChange}
                          />
                        </InputGroup>
                        {err && <span className="text-danger">{err}</span>}
                      </Form.Group>
                      <Row className="mb-3">
                        <Col xs={12} sm={{ order: "last" }}>
                          <Card.Link
                            as={Link}
                            to={Routes.ForgotPassword.path}
                            className="small text-end d-block"
                          >
                            Forgot password?
                          </Card.Link>
                        </Col>
                        <Col xs={12} sm={{ order: "first" }}>
                          <Form.Check type="checkbox">
                            <FormCheck.Input
                              id="defaultCheck5"
                              className="me-2"
                            />
                            <FormCheck.Label
                              htmlFor="defaultCheck5"
                              className="mb-0"
                            >
                              Remember me
                            </FormCheck.Label>
                          </Form.Check>
                        </Col>
                      </Row>
                      <Button
                        variant="primary"
                        type="submit"
                        className="w-100 mt-3"
                      >
                        Sign in
                      </Button>
                    </Form>
                  </Card>
                </Col>
              </Row>
            </Container>
          </section>
        </main>
      )}
    </>
  );
};



//           <Row className="justify-content-center form-bg-image" style={{ backgroundImage: `url(${BgImage})` }}>
//             <Col xs={12} className="d-flex align-items-center justify-content-center">
//               <div className="bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
//                 <div className="text-center text-md-center mb-4 mt-md-0">
//                   <h3 className="mb-0">Sign in</h3>
//                 </div>
//                     </InputGroup>
//                     {err && <span className="text-danger">{err}</span>}
//                   </Form.Group>
//                   <Row className="mb-3">
//                     <Col xs={12} sm={{ order: 'last' }} className="d-flex justify-content-center mb-2 mb-sm-0">
//                       <Card.Link as={Link} to={Routes.ForgotPassword.path} className="small">
//                         Forgot password?
//                       </Card.Link>
//                     </Col>
//                     <Col xs={12} sm={{ order: 'first' }} className="d-flex justify-content-center">
//                       <Form.Check type="checkbox">
//                         <FormCheck.Input id="defaultCheck5" className="me-2" />
//                         <FormCheck.Label htmlFor="defaultCheck5" className="mb-0">