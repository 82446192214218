import React from 'react'
import { Button } from "@themesberg/react-bootstrap";
import styles from '../assets/Styling/customstyle.css'
import { Routes } from '../routes';
import { DownloadReport } from "../service/api";
import { useHistory } from 'react-router-dom';

function ReportResultFooter(props) {
    const history = useHistory();

    const handleClick = () => {
        history.push(Routes.NewReports.path)
    }

    const handleDownload = async () => {

        try {
            const response = await DownloadReport(props.id);
            const blob = response.data;
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = "filename.xlsx";
            a.style.display = "none";
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.log("error", error);
        }

    };

    return (
        <>
            <div className='mx-3 p-2 '>
                <div className='row '>
                    <p className=''>
                        ..Positive amounts reflect tax savings, negative amounts reflect tax payments
                    </p>
                    <p className=''>
                        ...75% cap (1M liability - 750K max ITCs for current year)
                    </p>
                    <p className=''>
                        ....If you want to spread out income, you must elect your LLC to use Accrual method
                    </p>
                    <br />
                    <h3 className=''>Disclaimer</h3>
                    <p className={styles.disText}>
                        This is not a solicitation or offer to sell securities and should
                        not be construed as such, and no facts relating to actual investment
                        are presented. Discussion of U.S. Federal and State Tax Implications
                        of such investments is meant to be general in nature and does not
                        discuss all aspects of U.S. Federal and State Income Taxation that
                        may be relevant to a prospective Investor. None of this content may
                        be construed as providing tax advice.
                    </p>
                    <p className={styles.disText}>
                        Depreciation calculations assume solar assets are placed in service
                        in Q1, applying the full MACRS formula for the calendar year.
                        Variations in timing or methods may result in different outcomes.
                        Please consult a tax professional for personalized advice.
                    </p>
                    <br />
                    <h3 className={styles.disTextHead}>Statement of Confidentiality</h3>
                    <p className={styles.disText}>
                        The information in this document has been prepared by INCEPTION
                        FINANCIAL LLC, and any of its affiliates, including but not limited
                        to, Inception Financial Development, LLC. This document is private
                        and confidential and contains proprietary information. This document
                        is not for public distribution. INCEPTION FINANCIAL LLC provides
                        this document with the understanding that its contents will be held
                        in strict confidence, and will not be duplicated or used in whole or
                        part for any purpose other than evaluation of this document.
                    </p>
                </div>
                <div className=" mt-2 mb-2 d-flex justify-content-end">
                    <Button className='' title="Back" type="submit" variant="back" onClick={handleClick} >Back</Button>
                    <Button className='mx-2' title="Download Package" onClick={handleDownload}>Download Package</Button>
                </div>
            </div>

        </>
    )
}

export default ReportResultFooter