import clsx from "clsx";
import React, { useState } from "react";
import { Table, Card, Tab, Nav, Breadcrumb } from "@themesberg/react-bootstrap";
import '../assets/Styling/customstyle.css'

const Tables = (props) => {
  const { fields, data, headerFields = [], className, name = "", isEditable } = props;

  const scrollableTableStyle = {
    overflowX: 'auto',
  };

  return (

    <div className="flex justify-start w-full">
      <div className="">
        <Card className="mx-3 ">
          <Card.Body className="pt-0">
            <div style={scrollableTableStyle}>
              <Table hover className={clsx(
                "min-w-full"
              )}  >
                <thead className={clsx(" ")}>
                  <tr className={clsx(" my-table")} >
                    {headerFields.map((header, index) => (
                      <th
                        className={clsx(
                          "border",
                          // index == 1 ? "col-sm-6 bg-primary" : "col-sm-4"
                        )} >
                        <span className="text-[23px]  text-xl uppercase  ">
                          {header}
                        </span>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {data.map((row, rowIndex) => {
                    return (
                      <tr key={rowIndex} >
                        {row.map((cellData, cellIndex) => (
                          <td
                            key={cellIndex}
                            className={clsx(
                              "border",
                              cellIndex === 0 ? "col-sm-3 break-words" : "",
                              "maxWidth",
                              isEditable && "editable"
                            )}
                            contentEditable={isEditable}
                            suppressContentEditableWarning
                          >
                            {cellData ? cellData.toLocaleString().trim() : ""}
                          </td>
                        ))}
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          </Card.Body>
        </Card>
      </div>
    </div>
  );
};

export default Tables;