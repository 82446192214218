import React, { useState, useEffect } from "react";
import { Tab, Nav } from "@themesberg/react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Sidebar from "./Sidebar";
import Navbar from "./Navbar";
import { getUserInput } from "../service/api";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { ConstantTable } from "./Tables";
import ReportResult from "./ReportResult";
import "../assets/Styling/customstyle.css";
import { Breadcrumb } from "@themesberg/react-bootstrap";
import Footer from "./Footer";
import { useLocation } from "react-router-dom";

function ReportHistoryEdit() {
  const [activeKey, setActiveKey] = useState("input");
  const [data, setData] = useState([]);
  const location = useLocation();
  const searchTerm = new URLSearchParams(location.search);
  const id = searchTerm.get("id").toString();
  const [userInputId, setUserInputId] = useState(id);
  const [showSidebar, setShowSidebar] = useState(true);

  const handleResize = () => {
    if (window.innerWidth <= 763) {
      setShowSidebar(true);
    } else {
      setShowSidebar(true);
    }
  };

  useEffect(() => {
    getUserInputs();
  }, []);

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const getUserInputs = async () => {
    try {
      const data = await getUserInput(userInputId);
      let newData = data.data.data;
      setData(newData);
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <>
      {showSidebar && <Sidebar />}
      <div
        className={
          showSidebar ? "component" : "component-full-width"
        }>
        <div className="mx-3">
          <Navbar />
        </div>
        <div className="d-xl-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4 mx-3">
          <div className="d-block mb-4 mb-xl-0">
            <Breadcrumb
              className="d-none d-md-inline-block "
              listProps={{
                className: "breadcrumb-dark breadcrumb-transparent ",
              }}
            >
              <Breadcrumb.Item>
                <FontAwesomeIcon icon={faHome} />
              </Breadcrumb.Item>
              <Breadcrumb.Item active>Edit Past Reports</Breadcrumb.Item>
            </Breadcrumb>
            <h4>Edit Past Reports</h4>
          </div>
        </div>

        <Tab.Container
          activeKey={activeKey}
          onSelect={(k) => {
            setActiveKey(k);
          }}
          className=""
        >
          <div className=" d-flex flex-wrap justify-content-between ">
            <Nav variant="tabs" className="mx-4 nav-tabs custom-nav  " >
              <Nav.Item className="nav-item">
                <Nav.Link className="nav-link custom-tab" eventKey="input">
                  Input
                </Nav.Link>
              </Nav.Item>
              {/* <Nav.Item>
                   <Nav.Link className="custom-tab" eventKey="result">Result</Nav.Link>
                 </Nav.Item> */}
            </Nav>
          </div>

          <Tab.Content>
            <Tab.Pane eventKey="input" className="mx-3">
              <ConstantTable
                data={data}
                isUserInput={true}
                userInputId={userInputId}
              />
            </Tab.Pane>

            <Tab.Pane eventKey="result">
              <div className="mt-3">
                {/* <ReportResult /> */}
              </div>
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
        <div className="mx-3 my-3">
          <Footer />
        </div>
      </div>
    </>
  );
}

export default ReportHistoryEdit;