import * as yup from "yup";

export const tableSchema = yup.object({
  adminFeeStartingYear: yup
    .number()
    .required()
    .typeError("Admin Fee Starting  is Required"),
  assetManagement: yup
    .number()
    .required()
    .typeError("Asset Management is Required"),
  equityContribution: yup
    .number()
    .required()
    .typeError("Equity Contribution is Required"),
  assumedPercentageInveterWarranty: yup
    .number()
    .required()
    .typeError("Assumed Percentage Inveter Warranty is Required"),
  costPerExtendedInveterWarranty: yup
    .number()
    .required()
    .typeError("Cost Per Extended Inveter Warranty is Required"),
  developerDepositPayment1: yup
    .number()
    .required()
    .typeError("Developer Deposit Payment 1 is Required"),
  developerDepositPayment2: yup
    .number()
    .required()
    .typeError("Developer Deposit Payment 2 is Required"),
  developerDepositPayment3: yup
    .number()
    .required()
    .typeError("Developer Deposit Payment 3 is Required"),
  developerDevFee: yup
    .number()
    .required()
    .typeError("Developer Dev Fee is Required"),
  federalBonusDepreciation: yup
    .number()
    .required()
    .typeError("Federal Bonus Derreciation is Required"),
  federalBonusDepreciationPercent: yup
    .number()
    .required()
    .typeError("Federal Bonus Depreciation Percent is Required"),
  itcPercent: yup.number().required().typeError("ITC Percent is Required"),
  marginalFedTaxRate: yup
    .number()
    .required()
    .typeError("Marginal Fed Tax Rate is Required"),
  pPATerm: yup.number().required().typeError("PPA is Required"),
  percentagePropertyOwnerPurchases: yup
    .number()
    .required()
    .typeError("Percentage Property Owner Purchases is Required"),
  solarDegradation: yup
    .number()
    .required()
    .typeError("Solar Degradation is Required"),
  yrsAdminFeeEscalator: yup
    .number()
    .required()
    .typeError("Yrs Admin Fee Escalator is Required"),
  pandcCost: yup.number().required().typeError("PandC Cost is Required"),
  ppaPercentOfBillOfSale: yup
    .number()
    .required()
    .typeError("PPA % of Bill of Sale is Required"),
  ppaPercentOfTurnkey: yup
    .number()
    .required()
    .typeError("PPA % of Turnkey is Required"),
  turnkeyDC: yup.number().required().typeError("Turnkey $/W DC is Required"),
  percentOfPPAIncome: yup
    .number()
    .required()
    .typeError("Percent Of PPA Income is Required"),
  sizeKWDc: yup.number().required().typeError("Size KWDc  is Required"),
  reverseForExpenseInExcess: yup
    .number()
    .required()
    .typeError("Reverse For Expense In Excess is Required"),
  wholesaleReplecement: yup
    .number()
    .required()
    .typeError("Wholesale Replecement is Required"),
  replecementEscalator: yup
    .number()
    .required()
    .typeError("Replecement Escalator is Required"),
  feeCustomerDCPerYear: yup
    .number()
    .required()
    .typeError("Fee Customer DC per year is Required"),
  portfolioAve: yup.number().required().typeError("Portfolio Ave is Required"),
});
export const MacrsSchema = yup.object({
  macrsDRYear1: yup.number().required().typeError("Year 1 is Required*"),
  macrsDRYear2: yup.number().required().typeError("Year 2 is Required*"),
  macrsDRYear3: yup.number().required().typeError("Year 3 is Required*"),
  macrsDRYear4: yup.number().required().typeError("Year 4 is Required*"),
  macrsDRYear5: yup.number().required().typeError("Year 5 is Required*"),
  macrsDRYear6: yup.number().required().typeError("Year 6 is Required*"),
});
export const stateSchema = yup.object({
  state: yup.string().required().typeError("State is Required*"),
  stateTaxRatePercent: yup
    .number()
    .required()
    .typeError("State Tax Percent is Required*"),
  stateTaxRatePercentYrs7_20: yup
    .number()
    .required()
    .typeError("State Tax Rate Percent yrs is Required*"),
  statePTEPaymentPercent: yup
    .number()
    .required()
    .typeError("State PTE Payment Percent is Required*"),
  stateBonusDepreciation: yup
    .string()
    .required()
    .typeError("State Bonus Depreciation is Required*"),
  stateDepreciation: yup
    .string()
    .required()
    .typeError("State Depreciation is Required*"),
  stateRevenueSpread: yup.string(),
});
export const resetSchema = yup.object({
  password: yup
    .string()
    .min(8, "Password should be 8 characters long")
    .required("Password is required*")
    .label("Password"),
  confirmPassword: yup
    .string()
    .required("Confirm Password is required")
    .oneOf([yup.ref("password")], "Passwords must match")
    .label("Confirm Password"),
});
export const subAdminSchema = yup.object({
  name: yup.string().required("Name is required").label("Name"),
  password: yup.string().required(" Password is required").label("Password"),
});
export const reportInputSchema = yup.object({
  // grossIncome: yup
  //   .string()
  //   .test("onlyOneFilled", "Only one input should be filled", function (value) {
  //     const equalityContribution = this.parent.equalityContribution;
  //     console.log(equalityContribution);
  //     return !(value && equalityContribution);
  //   }),
  // equalityContribution: yup
  //   .string()
  //   .test("onlyOneFilled", "Only one input should be filled", function (value) {
  //     const grossIncome = this.parent.grossIncome;
  //     return !(value && grossIncome);
  //   }),
  equalityContribution: yup.string().when("grossIncome", {
    is: (grossIncome) => !grossIncome,
    then: () => yup.string().required("Equity Contribution is required*"),
  }),
  grossIncome: yup.string().optional(),
  stateId: yup.string().required("State is required*"),
  estFederalTaxRate: yup
    .string()
    .required("Estimated Federal Tax Rate is required*"),
});

export const replySchema = yup.object({
  reply: yup.string().trim().required("Reply is required"),
});

export const zohoIdSchema = yup.object({
  zohoId: yup.string().trim().required("Zoho Id is required"),
});

export const contactOwner = yup.object({
  zohoCustomerId: yup.string().trim().required("Zoho Customer Id is required"),
  ownerName: yup.string().trim(),
  ownerRole: yup.string().trim(),
  ownerPhone: yup.string().trim(),
  ownerEmail: yup.string().trim(),
  subOwnerName: yup.string().trim(),
  subOwnerRole: yup.string().trim(),
  subOwnerEmail: yup.string().trim(),
  subOwnerPhone: yup.string().trim(),
});

export const proformaUser = yup.object({
  userName: yup.string().trim().required("User Name is required"),
  pin: yup.number().typeError("Pin is required").required("Pin is required"),
  folderId: yup.string().trim()
    .test("required", "FolderId is required", function (value, context) {
      if (context.options.context?.isExternal && !value) {
        return this.createError({

          message: "FolderId is required",
        });
      }
      if (context.options.context?.isEquity && value) {
        return true;
      }
      return true;
    }),
});
