import { Table, Card } from "@themesberg/react-bootstrap";
import "../assets/Styling/customstyle.css";
import clsx from "clsx";
import React from "react";

const TableTop = (props) => {
  const { fields, data, headerFields = [], className, name = "" } = props;
  return (
    <div className="flex justify-start w-full ">
      <div className="">
        <Card className="table-wrapper shadow-sm form-card1 ">
          <Card.Body className="pt-0">
            <div className="table-responsive">
              <Table hover className="user-table align-items-center my-table ">
                <thead>
                  <tr>
                    <th>BENEFITS SUMMARY</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((row, index) => (
                    <tr key={index}>
                      {row.map((data, index) => (
                        <td key={index}>{data}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </Card.Body>
        </Card>
      </div>
    </div>
  );
};

export default TableTop;