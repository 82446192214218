import React, { useState, useEffect } from "react";
import { Table, Card, Tab, Nav, Breadcrumb } from "@themesberg/react-bootstrap";
import "../assets/Styling/customstyle.css";
import Sidebar from "./Sidebar";
import Navbar from "../components/Navbar";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ResultContent from "./ResultContent";
import { useLocation } from 'react-router-dom';

function ReportResult() {
  const location = useLocation();
  const [showSidebar, setShowSidebar] = useState(true);


  const handleResize = () => {
    if (window.innerWidth <= 763) {
      setShowSidebar(true);
    } else {
      setShowSidebar(true);
    }
  };


  useEffect(() => {
    handleResize(); // To set initial state based on window width
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);


  return (
    <>
      {showSidebar && <Sidebar />}

      <div
        className={
          showSidebar ? "component" : "component-full-width"
        }>
        <div className="mx-3">
          <Navbar />
        </div>

        <div className="d-xl-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4 mx-3">
          <div className="d-block mb-4 mb-xl-0">
            <Breadcrumb
              className="d-none d-md-inline-block "
              listProps={{
                className: "breadcrumb-dark breadcrumb-transparent ",
              }}
            >
              <Breadcrumb.Item>
                <FontAwesomeIcon icon={faHome} />
              </Breadcrumb.Item>
              <Breadcrumb.Item active>Report Result</Breadcrumb.Item>
            </Breadcrumb>
            <h4>Report Result</h4>
          </div>
        </div>
        <ResultContent location={location} />
      </div>
    </>
  );
}

export default ReportResult;