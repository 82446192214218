import React, { useEffect, useState } from "react";
import Table from '../components/Table'
import TableTop from "./TableTop";
import { ReportDetails } from "../service/api";
import ReportResultFooter from "./ReportResultFooter";
import { Routes } from '../routes';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { runReport, UpdateReport, DownloadReport } from "../service/api";

import { Card, Tab, Nav, Breadcrumb, Button } from "@themesberg/react-bootstrap";
import "../assets/Styling/customstyle.css";

const ResultContent = (props) => {
  const [tableType, setTableType] = useState("6 Year Proforma");
  const [table, setTable] = useState(props.location?.state);
  const [isEditable, setIsEditable] = useState(false);
  const [activeTab, setActiveTab] = useState('6 Years');
  const handleSelectTab = (tabName) => {
    setActiveTab(tabName);
    if (tabName === '6 Years') {
      setTableType("6 Year Proforma");
      setYear(0);
    } else {
      setTableType("20 Year Proforma");
      setYear(1);
    }
  }

  const GernateReport = async () => {
    const response = await UpdateReport({ userInputId: props.location.state.inputId, userId: props.location.state.userId });
    if (response) {
      // setResult(response.data.data);
      console.log(response.data.data)
    }
  }

  const handleUpdate = () => {
    GernateReport();
  }

  const handleClickEdit = () => {
    setIsEditable(true);
  };

  const history = useHistory();

  const handleClick = () => {
    history.push(Routes.NewReports.path)
  }

  const handleDownload = async () => {

    try {
      const response = await DownloadReport(props.location?.state?._id);
      const blob = response.data;
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "filename.xlsx";
      a.style.display = "none";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.log("error", error);
    }

  };

  const [investorYearData, setInvestorYearData] = useState({
    headers: ["Investor Return", "Year"],
    fields: ["", "Annual Benefit"],
    data: [Array.from({ length: 20 }, (_, index) => index + 1), []],
  });

  const addFields = (data, fields, slice, sliceNum) => {
    return data?.map((d, index) => {
      if (slice) {
        return [fields[index], ...d?.slice(0, sliceNum ? sliceNum : 7)];
      } else {
        return [fields[index], ...d];
      }
    });
  };

  const [year, setYear] = useState(0);

  const benefits = {
    headers: ["Benefits Summary"],
    fields: [
      "Equality Contribution",
      "Net Benefit",
      "Net Benefit Percentage",
      "Operating Exp",
      "Total Benefit",
    ],
    data: table?.summary[year] ? [
      [`${table?.summary[year].equalityContribution.toLocaleString()}`],
      [`${table?.summary[year].netBenefit.toLocaleString()}`],
      [`${table?.summary[year].netBenefitPercentage}%`],
      [`${table?.summary[year].operatingExp.toLocaleString()}`],
      [`${table?.summary[year].totalBenefit.toLocaleString()}`],
    ] : [],
  };

  const investorYear = {
    headers: ["Investor Return", "Year"],
    fields: ["", "Annual Benefit"],
    data: [Array.from({ length: 20 }, (_, index) => index + 1), []],
  };



  useEffect(() => {
    if (table?.investorReturn && table?.investorReturn.length > 0) {
      const updatedData = table?.investorReturn.map((item, index) => item.annualBenefit);
      setInvestorYearData(prevState => ({
        ...prevState,
        data: [
          Array.from({ length: 20 }, (_, index) => index + 1),
          updatedData,
        ],
      }));

    }
  }, [table]);


  const fieldsToExtract = [
    "assetPurchasePrice",
    "prepaymentIncomeFederal",
    "adminFeeFromPropertyHost",
    "equalityContribution",
    "operatingExp",
    "federalDeprecitaionNOL",
    "federalTaxCredit",
    "prepaymentIncomeState",
    "stateDepreciationNOL",
    "netBenefit",
  ];
  const fieldValues = fieldsToExtract.map((field) => {
    return table?.investorReturn?.map((obj) => obj[field]) || [];
  });

  const macroYear = {
    headers: ["MACROECONOMICS"],
    fields: [
      "Asset Purchase Price",
      "Prepayment Income (Federal)",
      "Admin Fee from Property Host",
      "Equity Contribution",
      "Operating Expenses",
      "Federal Deprecitaion/NOL",
      "Federal Tax Credit",
      "Prepayment Income (State)",
      "State Depreciation/NOL",
      "Net Benefit",
    ],
    data: fieldValues,
  };

  const incomeTaxFieldsToExtract = [
    "year",
    "",
    "prepaymentIncomeFederal",
    "adminFeeFromPropertyHost",
    "prepaymentIncomeFederal",
    "",
    "operatingExp",
    "operatingExp",
    "ebitda",
    "",
    "bonus80Percent",
    "macrsDepRate",
    "federalDeprecitaionNOL",
    "nolCarryForward",
    "ebit",
    "incomeBeforeIncomeTax",
    "",
    "",
    "prepaymentIncomeState",
    "operatingExp",
    "macrsDepRate",
    // "prepaymentIncomeState",
    "stateDepreciationNOL",
    "stateAssetsSale",
    "",
    "",
    "totalStateIncome",
    "stateIncomeTaxRate",
    "stateIncomeTaxSavingPayment",
    "",
    "statePteTaxPercent",
    "statePteTaxPayment",
    "stateIncomeAfterPtePayment",
    "federalIncomeTaxRate",
    "federalIncomeTaxSaving",
    "",
    "totalFedAndIncomeTaxSaving",
    "federalTaxCredit",
    "annualBenefit",
  ];

  const incomeTaxFieldValues = incomeTaxFieldsToExtract.map((field) => {
    return table?.investorReturn?.map((obj) => obj[field]) || [];
  });

  const incomeTaxField = {
    headers: ["INCOME TAX STATEMENT", "Year"],
    fields: [
      "",
      "REVENUE",
      "Prepayment from Property Host",
      "Admin Fee from Property Host",
      "Total Federal Revenue",
      "Operating Expenses",
      "Asset Management",
      "Total Opex",
      "EBITDA",
      "FEDERAL DEPRECATION",
      "Bonus (80%)",
      "MACRS Depreciation Rate",
      // "Remaining MACRS Depreciation",
      "Depreciation/NOL Used",
      "NOL Carry-Forward",
      "EBIT",
      "INCOME BEFORE INCOME TAX",
      "TAX CREDITS AND INCOME TAX",
      "State",
      "State Revenue - from Prepayment",
      "Total Operating Expenses",
      "Depreciation rate",
      "State Depreciation",
      "State Asset Sale",
      "State Carryover Loss for Non Sales Tax State",
      "Federal Depreciation Basis Reduction Add Back",
      "Total State Income",
      "State Income tax rate",
      "State Income Tax Savings (Payments)*",
      "Federal",
      "State PTE Tax %",
      "State PTE Tax Payment",
      "Federal income after PTE Payment",
      "Federal income tax rate",
      "Federal Income Tax Savings (Payments)*",
      "State & Federal",
      "Total Fed & State Income Tax Savings (Payments)*",
      "Federal Tax Credit",
      "Total Income Tax Savings (Payments)*",
    ],
    data: incomeTaxFieldValues,
  };

  const taxAndCashStatementFieldsToExtract = [
    "year",
    "totalNetCF_PRETAX",
    "",
    "totalStateIncome",
    "stateIncomeTaxSavingPayment",
    "stateIncomeAfterPtePayment",
    "federalIncomeTaxSaving",
    "totalFedAndIncomeTaxSaving",
    "federalTaxCredit",
    "totalNetIncomeTaxSavingPayment",
    "annualBenefit",
  ];

  const taxAndCashStatementFieldValues = taxAndCashStatementFieldsToExtract.map(
    (field) => {
      return table?.investorReturn?.map((obj) => obj[field]) || [];
    }
  );

  const taxAndCashStatement = {
    headers: ["TAX & CASH STATEMENT", "Year"],
    fields: [
      "",
      "TOTAL NET CF (PRETAX)",
      "INCOME TAX",
      "State income",
      "State Income Tax Savings (Payments)*",
      "Federal income",
      "Fed Income Tax Savings (Payments)*",
      "Total Income Tax Savings (Payments)*",
      "Federal Tax Credit",
      "Total Net Income Tax Savings (Payments)*",
      "TOTAL NET TAX & CASH BENEFIT",
    ],
    data: taxAndCashStatementFieldValues,
  };


  const yr6DataFor20yrs = table?.investorReturn.find((e) => {
    return e.year == 6 && Boolean(e.is20Year) == true;
  });

  if (tableType === "6 Year Proforma") {
    investorYear.data[0].splice(6, 0, "6 Yr Total");
    investorYear.data[1].splice(6, 0, table?.yearsTotal[year].annualBenefit);
    macroYear.data[0].splice(6, 0, table?.yearsTotal[year].assetPurchasePrice);
    macroYear.data[1].splice(
      6,
      0,
      table?.yearsTotal[year].prepaymentIncomeFederal
    );
    macroYear.data[2].splice(
      6,
      0,
      table?.yearsTotal[year].adminFeeFromPropertyHost
    );
    macroYear.data[3].splice(
      6,
      0,
      table?.yearsTotal[year].equalityContribution
    );
    macroYear.data[4].splice(6, 0, table?.yearsTotal[year].operatingExp);
    macroYear.data[5].splice(
      6,
      0,
      table?.yearsTotal[year].federalDeprecitaionNOL
    );
    macroYear.data[6].splice(6, 0, table?.yearsTotal[year].federalTaxCredit);
    macroYear.data[7].splice(
      6,
      0,
      table?.yearsTotal[year].prepaymentIncomeState
    );
    macroYear.data[8].splice(
      6,
      0,
      table?.yearsTotal[year].stateDepreciationNOL
    );
    macroYear.data[9].splice(6, 0, table?.yearsTotal[year].netBenefit);
  } else {
    investorYear.data[1][5] = yr6DataFor20yrs?.annualBenefit;
    investorYear.data[0].splice(6, 1, 7);
    investorYear.data[0].splice(20, 0, "20 Yr Total");
    investorYear.data[1].splice(20, 0, table?.yearsTotal[year].annualBenefit);
    macroYear.data[0].splice(20, 0, table?.yearsTotal[year].assetPurchasePrice);
    macroYear.data[1].splice(
      20,
      0,
      table?.yearsTotal[year].prepaymentIncomeFederal
    );
    macroYear.data[2].splice(
      20,
      0,
      table?.yearsTotal[year].adminFeeFromPropertyHost
    );
    macroYear.data[3].splice(
      20,
      0,
      table?.yearsTotal[year].equalityContribution
    );
    macroYear.data[4].splice(20, 0, table?.yearsTotal[year].operatingExp);
    macroYear.data[5].splice(
      20,
      0,
      table?.yearsTotal[year].federalDeprecitaionNOL
    );
    macroYear.data[6].splice(20, 0, table?.yearsTotal[year].federalTaxCredit);
    macroYear.data[7].splice(
      20,
      0,
      table?.yearsTotal[year].prepaymentIncomeState
    );
    macroYear.data[8].splice(
      20,
      0,
      table?.yearsTotal[year].stateDepreciationNOL
    );
    macroYear.data[9].splice(20, 0, table?.yearsTotal[year].netBenefit);
  }


  // investorYear?.data[0].pop();
  // macroYear.data.map((e) => e.pop());

  return (
    <div className="table-responsive1">
      <div className="mb-2 d-flex flex-wrap justify-content-between tab-result ">
        <Nav variant="tabs" className="mx-3 nav-tabs ">
          <Nav.Item className="nav-item">
            <Nav.Link
              title="6 Years"
              onClick={() => handleSelectTab('6 Years')}
              active={activeTab === '6 Years'}
              className={activeTab === '6 Years' ? 'nav-link custom-tab dark' : 'nav-link custom-tab'}
              eventKey="tabel1">
              6 Years
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              onClick={() => handleSelectTab('20 Years')}
              active={activeTab === '20 Years'}
              className={activeTab === '20 Years' ? 'nav-link custom-tab dark' : 'nav-link custom-tab'}
              eventKey="tabel2">
              20 Years
            </Nav.Link>
          </Nav.Item>
        </Nav>
        <div className="btn-resp d-flex justify-content-center align-item-center flex-wrap ">
          {/* <Button className='mb-3' title="Back" type="submit" variant="back" onClick={handleClick} >Back</Button> */}
          <Button className='mx-4 mb-3' title="Download Package" onClick={handleDownload}>Download Package</Button>
        </div>
      </div>


      <div className="mb-2">
        <div className='row justify-content-between align-items-start '>
          <div className="col-md-6">
            <TableTop
              headerFields={benefits.headers}
              fields={benefits.fields}
              data={addFields(benefits.data, benefits.fields, false)}
              isEditable={isEditable}
            />
          </div>
          {/* <div className="col-6 d-flex justify-content-end  btn-resp ">
            <div className="col-5 ">
              <Button className='' title="Back" type="submit" variant="back" onClick={handleClick} >Back</Button>
              <Button className='mx-2' title="Download Package" onClick={handleDownload}>Download Package</Button>
            </div>
          </div> */}
        </div>
      </div>

      {tableType === "6 Year Proforma" && (
        <div className="overflow-y-hidden">
          <Table
            className="overflow-x-hidden"
            headerFields={investorYear.headers}
            fields={investorYear.fields}
            data={addFields(investorYear.data, investorYear.fields, true)}
            isEditable={isEditable}
            name=""
          />
          <Table
            className="overflow-x-hidden"
            headerFields={macroYear.headers}
            fields={macroYear.fields}
            data={addFields(macroYear.data, macroYear.fields, true)}
            isEditable={isEditable}
            name="w-[100px] min-w-[250px]"
          />
          <Table
            className="overflow-x-hidden"
            headerFields={incomeTaxField.headers}
            fields={incomeTaxField.fields}
            data={addFields(
              incomeTaxField.data,
              incomeTaxField.fields,
              true,
              6
            )}
            isEditable={isEditable}
            name="w-[100px] min-w-[250px]"
          />
        </div>
      )}

      {tableType === "20 Year Proforma" && (
        <div className="overflow-x-auto">
          <Table
            className="overflow-x-hidden"
            headerFields={investorYear.headers}
            fields={investorYear.fields}
            data={addFields(investorYear.data, investorYear.fields, false)}
            isEditable={isEditable}
            name="w-[100px] min-w-[250px]"
          />
          <Table
            className="overflow-x-hidden"
            headerFields={incomeTaxField.headers}
            fields={incomeTaxField.fields}
            data={addFields(
              incomeTaxField.data,
              incomeTaxField.fields,
              true,
              20
            )}
            isEditable={isEditable}
            name="w-[100px] min-w-[250px]"
          />
          <Table
            className="overflow-x-hidden"
            headerFields={taxAndCashStatement.headers}
            fields={taxAndCashStatement.fields}
            data={addFields(
              taxAndCashStatement.data,
              taxAndCashStatement.fields,
              true,
              20
            )}
            isEditable={isEditable}
            name="w-[100px] min-w-[250px]"
          />
        </div>
      )}
      <ReportResultFooter id={props.location?.state?._id} />
    </div>
  );
};

export default ResultContent;