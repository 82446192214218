import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

toast.configure();

export const showNotification = (type, message) => {
  if (type == "error") {
    toast.error(message, {
      position: toast.POSITION.TOP_RIGHT,
    });
    return;
  }
  toast.success(message, {
    position: toast.POSITION.TOP_RIGHT,
  });
};
