import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'
import { Card } from "@themesberg/react-bootstrap";

import React from 'react'

export const TableSkeleton = () => {
    return (
        <Card className="mx-3 table-responsive">
            <Card.Body>
                <table className="table">
                    <thead>
                        <tr>
                            <th>
                                <Skeleton width={100} />
                            </th>
                            <th>
                                <Skeleton width={100} />
                            </th>
                            <th>
                                <Skeleton width={100} />
                            </th>
                            <th>
                                <Skeleton width={100} />
                            </th>
                            <th>
                                <Skeleton width={100} />
                            </th>
                            <th>
                                <Skeleton width={100} />
                            </th>
                            <th>
                                <Skeleton width={100} />
                            </th>
                            <th>
                                <Skeleton width={100} />
                            </th>
                            <th>
                                <Skeleton width={100} />
                            </th>
                            <th>
                                <Skeleton width={100} />
                            </th>
                            <th>
                                <Skeleton width={100} />
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {Array.from({ length: 12 }).map((_, index) => (
                            <tr key={index}>
                                <td>
                                    <Skeleton width={100} />
                                </td>
                                <td>
                                    <Skeleton width={100} />
                                </td>
                                <td>
                                    <Skeleton width={100} />
                                </td>
                                <td>
                                    <Skeleton width={100} />
                                </td>
                                <td>
                                    <Skeleton width={100} />
                                </td>
                                <td>
                                    <Skeleton width={100} />
                                </td>
                                <td>
                                    <Skeleton width={100} />
                                </td>
                                <td>
                                    <Skeleton width={100} />
                                </td>
                                <td>
                                    <Skeleton width={100} />
                                </td>
                                <td>
                                    <Skeleton width={100} />
                                </td>
                                <td>
                                    <Skeleton width={100} />
                                </td>
                                <td>
                                    <Skeleton width={100} />
                                </td>
                                <td>
                                    <Skeleton width={100} />
                                </td>

                            </tr>
                        ))}
                    </tbody>
                </table>
            </Card.Body>
        </Card>
    )
};