import { faHome } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { Breadcrumb } from 'react-bootstrap'
import styles from './addProforma.module.css'
import Select from 'react-select'
import { useForm } from 'react-hook-form'
import { getProformaUser, getProformaUsers, registerProformaUser, updateProformaValues } from '../../service/api'
import { showNotification } from '../../service/showNotification'
import ProformaUserTable from '../../components/ProformaUserTable/index'
import { yupResolver } from '@hookform/resolvers/yup'
import { proformaUser } from '../../yup'

const AddProformaUser = () => {
    const runOptions = [{
        label: "Internal",
        value: "Internal"
    },
    {
        label: "External",
        value: "External"
    }]
    const [selectedOptions, setSelectedOptions] = useState(runOptions[0])
    const [userData, setUserData] = useState([]);
    const { register, handleSubmit, reset, formState: { errors } } = useForm({ resolver: yupResolver(proformaUser), context: { isExternal: selectedOptions.label === "External" } })
    const { register: register2, handleSubmit: handleSubmit2, reset: reset2 } = useForm()

    const getUsers = async () => {
        try {
            const response = await getProformaUsers();
            const data = response.data.data;
            setUserData(data)
        } catch (error) {
            console.log(error);
        }
    };

    const onSubmit = async (values) => {
        try {
            const data = {
                name: values.userName,
                pin: values.pin,
                designation: selectedOptions.value,
            }
            if (selectedOptions.label === "External") {
                data["inceptionDevFee"] = values.inceptionDevFee;
                data["developerDevFee"] = values.developerDevFee;
                data["itcPercent"] = values.itcPercent;
                data["folderId"] = values.folderId;
            }
            const response = await registerProformaUser(data)
            if (response.data.success) {
                showNotification("success", "User created successfully")
                getUsers()
                reset({
                    userName: "",
                    pin: null,
                })
            }
        }
        catch (error) {
            showNotification("error", error.response.data.message || "Something went wrong")
        }
    }
    const onDefaultValueSubmit = async (values) => {
        try {
            const data = {
                inceptionDevFee: values.inceptionDevFeeDefault,
                developerDevFee: values.developerDevFeeDefault,
                itcPercent: values.itcPercentDefault,
                folderId: values.folderIdDefault,
            }
            const response = await updateProformaValues(data)
            if (response.data.success) {
                showNotification("success", "Default values updated successfully")
                fetchProformaValues()
            }
        }
        catch (error) {
            showNotification("error", error.response.data.message || "Something went wrong")
        }
    }

    const fetchProformaValues = async () => {
        const response = await getProformaUser()
        const data = response.data.data[0];
        console.log(data, "daat")
        reset({
            inceptionDevFee: data?.inceptionDevFee,
            developerDevFee: data?.developerDevFee,
            itcPercent: data?.itcPercent,
        })
        reset2({
            inceptionDevFeeDefault: data?.inceptionDevFee,
            developerDevFeeDefault: data?.developerDevFee,
            itcPercentDefault: data?.itcPercent,
            folderIdDefault: data?.folderId
        })
    }

    useEffect(() => {
        fetchProformaValues()
    }, [])

    useEffect(() => {
        getUsers();
    }, []);

    return (
        <>
            <div className="d-xl-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4 mx-3">
                <div className="d-block mb-4 mb-xl-0">
                    <Breadcrumb
                        className="d-none d-md-inline-block "
                        listProps={{
                            className: "breadcrumb-dark breadcrumb-transparent ",
                        }}
                    >
                        <Breadcrumb.Item>
                            <FontAwesomeIcon icon={faHome} />
                        </Breadcrumb.Item>
                        <Breadcrumb.Item active>Proforma Users</Breadcrumb.Item>
                    </Breadcrumb>
                    <h4>Proforma users</h4>
                </div>
            </div>
            <form onSubmit={handleSubmit(onSubmit)} className={styles.outerFormContainer}>
                <p className={styles.heading}>Create Pro Forma User</p>
                <div className={styles.formContainer}>
                    <div className={styles.inputContainer}>
                        <label className={styles.labelStyle}>User Name</label>
                        <input {...register("userName")} className={styles.inputBox} placeholder='Enter user name' type="text" />
                        {errors?.userName && <span className={styles.errorText}>{errors?.userName?.message}</span>}
                    </div>
                    <div className={styles.inputContainer}>
                        <label className={styles.labelStyle}>Pin</label>
                        <input {...register("pin")} className={styles.inputBox} placeholder='Enter Pin' type="number" />
                        {errors?.pin && <span className={styles.errorText} >{errors?.pin?.message}</span>}
                    </div>
                    <div className={styles.inputContainer}>
                        <label className={styles.labelStyle}>Designation</label>
                        <Select
                            options={runOptions}
                            value={{ value: selectedOptions.value, label: selectedOptions.label }}
                            onChange={(selected) => setSelectedOptions(selected)}
                        />
                    </div>
                    {
                        selectedOptions.value == "External" && <>
                            <div className={styles.inputContainer}>
                                <label className={styles.labelStyle}>Baseline Markup %</label>
                                <input {...register("inceptionDevFee")} className={styles.inputBox} placeholder='Enter Baseline Markup %' type="text" />
                            </div>
                            <div className={styles.inputContainer}>
                                <label className={styles.labelStyle}>Baseline Dev Fee %</label>
                                <input {...register("developerDevFee")} className={styles.inputBox} placeholder='Enter Baseline Dev Fee %' type="text" />
                            </div>
                            <div className={styles.inputContainer}>
                                <label className={styles.labelStyle}> Baseline ITC %</label>
                                <input {...register("itcPercent")} className={styles.inputBox} placeholder='Enter Baseline ITC %' type="text" />
                            </div>
                            <div className={styles.inputContainer}>
                                <label className={styles.labelStyle}>Destination Folder ID</label>
                                <input {...register("folderId")} className={styles.inputBox} placeholder='Enter folder ID' type="text" />
                                {errors?.folderId && <span className={styles.errorText}>{errors?.folderId?.message}</span>}
                            </div>
                        </>
                    }
                </div>
                <input className={styles.submitButton} type="submit" />
            </form>
            <form onSubmit={handleSubmit2(onDefaultValueSubmit)} className={styles.outerFormContainer}>
                <p className={styles.heading}>Baseline Pro Forma Values</p>
                <div className={styles.formContainer}>
                    <div className={styles.inputContainer}>
                        <label className={styles.labelStyle}>Inception Dev Fee %</label>
                        <input {...register2("inceptionDevFeeDefault")} className={styles.inputBox} placeholder='Enter Inception Dev Fee %' type="text" />
                    </div>
                    <div className={styles.inputContainer}>
                        <label className={styles.labelStyle}>Developer Dev Fee %</label>
                        <input {...register2("developerDevFeeDefault")} className={styles.inputBox} placeholder='Enter Developer Dev Fee %' type="text" />
                    </div>
                    <div className={styles.inputContainer}>
                        <label className={styles.labelStyle}> ITC %</label>
                        <input {...register2("itcPercentDefault")} className={styles.inputBox} placeholder='Enter ITC %' type="text" />
                    </div>
                    <div className={styles.inputContainer}>
                        <label className={styles.labelStyle}>Destination Folder ID</label>
                        <input {...register2("folderIdDefault")} className={styles.inputBox} placeholder='Enter folder ID' type="text" />
                    </div>
                </div>
                <input className={styles.submitButton} type="submit" />
            </form>
            <div className={styles.outerFormContainer}>
                <p className={styles.heading}>Users List</p>
                <ProformaUserTable data={userData} getUsers={getUsers} />
            </div>
        </>
    )
}

export default AddProformaUser