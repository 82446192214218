import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleLeft,
  faEnvelope,
  faUnlockAlt,
} from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Row,
  Form,
  Card,
  Button,
  Container,
  InputGroup,
} from "@themesberg/react-bootstrap";
import { Link, useHistory, useParams } from "react-router-dom";

import { Routes } from "../../routes";
import { subAdminProfile, verifyToken } from "../../service/api";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { subAdminSchema } from "../../yup";

export default () => {
  const { token } = useParams();
  const [data, setData] = useState({});
  const history = useHistory();
  const { register, handleSubmit } = useForm({
    resolver: yupResolver(subAdminSchema),
    mode: "onChange",
  });
  const [mess, setMess] = useState("");
  const [error, setError] = useState("");
  const [responseError, setResponseError] = useState("");
  const verifyAccessToken = async () => {
    const resp = await verifyToken(token);
    if (resp.data.success) {
      setData(resp.data.data);
    } else {
      setError(resp.data.error);
    }
  };

  const onSubmit = async (values) => {
    const { password, name } = values;
    const resp = await subAdminProfile({ token, name, password });
    if (resp.data.success) {
      setMess(resp.data.message);
      history.push(Routes.Signin.path);
    } else {
      setResponseError(resp.error);
    }
  };
  useEffect(() => {
    verifyAccessToken();
  }, []);
  return (
    <main>
      <section className="bg-soft d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
        {error ? (
          <span className="text-danger">Access Token Not valid</span>
        ) : (
          <Container>
            <Row className="justify-content-center">
              <Col
                xs={12}
                className="d-flex align-items-center justify-content-center"
              >
                <div className="bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                  <h3 className="mb-4">Profile setup</h3>
                  <Form onSubmit={handleSubmit(onSubmit)}>
                    <Form.Group id="email" className="mb-4">
                      <Form.Label>Email</Form.Label>
                      <InputGroup>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faEnvelope} />
                        </InputGroup.Text>
                        <Form.Control
                          autoFocus
                          required
                          defaultValue={data?.email}
                          type="email"
                          readOnly
                          placeholder="example@company.com"
                        />
                      </InputGroup>
                    </Form.Group>
                    <Form.Group id="name" className="mb-4">
                      <Form.Label>Full Name</Form.Label>
                      <InputGroup>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faUnlockAlt} />
                        </InputGroup.Text>
                        <Form.Control
                          required
                          {...register("name")}
                          defaultValue={data?.name}
                          type="text"
                          readOnly
                          placeholder="Enter your name"
                        />
                      </InputGroup>
                    </Form.Group>
                    <Form.Group id="password" className="mb-4">
                      <Form.Label>Password</Form.Label>
                      <InputGroup>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faUnlockAlt} />
                        </InputGroup.Text>
                        <Form.Control
                          required
                          type="password"
                          placeholder="Password"
                          {...register("password")}
                        />
                      </InputGroup>
                    </Form.Group>

                    <Button variant="primary" type="submit" className="w-100">
                      Submit
                    </Button>
                    {responseError && (
                      <span className="text-danger">{responseError}</span>
                    )}
                    {mess && <span className="text-success">{mess}</span>}
                  </Form>
                </div>
              </Col>
            </Row>
          </Container>
        )}
      </section>
    </main>
  );
};
