import React from 'react';
import { Controller } from 'react-hook-form';
import {
    Form
} from "@themesberg/react-bootstrap";
const NumberFormatInput = ({ control, name, placeholder, defaultValue }) => {
    const handleInputChange = (event) => {
        let { value } = event.target;
        value = value.replace(/[^0-9]/g, '');
        const formattedValue = value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        event.target.value = value != "" ? `$${formattedValue}` : ""
    };
    return (
        <Controller
            control={control}
            name={name}
            render={({ field, name }) => (
                <Form.Control
                    {...field}
                    defaultValue={defaultValue}
                    onChange={(e) => {
                        handleInputChange(e);
                        field.onChange(e);
                    }}
                    placeholder={placeholder}
                />
            )}
        />
    );
};

export default NumberFormatInput;