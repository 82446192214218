import React from "react";
import Layout from "../../components/Layout";

export default () => {
  return (
    <Layout>
      <div className="h-90vh">Dashboard</div>
    </Layout>
  );
};
