import React, { useEffect, useState } from "react";
import styles from "./index.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { Button, Breadcrumb } from "@themesberg/react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { contactOwner } from "../../yup";
import { getContactOwner, updateContactOwner } from "../../service/api";
import { showNotification } from "../../service/showNotification";

export const ContactOwner = () => {
  const {
    register,
    watch,
    getValues,
    setValue,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(contactOwner),
  });

  const [disableInput, setDisableInput] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const submitOwner = async (values) => {
    setIsLoading(true);
    try {
      const result = await updateContactOwner(values);
      if (!result.success) throw new Error(result.message);
      reset();
      setDisableInput(true);
    } catch (err) {
      console.log("Error", err);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchOwnerData = async (id) => {
    try {
      const result = await getContactOwner(id);
      if (!result.success) throw new Error(result.message);
      const { data } = result;
      setValue("ownerName", data.ownerName);
      setValue("ownerRole", data.ownerRole);
      setValue("ownerPhone", data.ownerPhone);
      setValue("ownerEmail", data.ownerEmail);
      setValue("subOwnerName", data.subOwnerName);
      setValue("subOwnerRole", data.subOwnerRole);
      setValue("subOwnerEmail", data.subOwnerEmail);
      setValue("subOwnerPhone", data.subOwnerPhone);
      setDisableInput(false);
    } catch (err) {
      showNotification("error", "Zoho customer id not found");
      console.log("Error", err);
    }
  };

  useEffect(() => {
    const zohoCustomerId = getValues("zohoCustomerId");
    if (zohoCustomerId.length === 19) {
      fetchOwnerData(zohoCustomerId);
    }
  }, [watch("zohoCustomerId")]);

  return (
    <>
      <div className="d-xl-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4 mx-3">
        <div className="d-block mb-4 mb-xl-0">
          <Breadcrumb
            className="d-none d-md-inline-block "
            listProps={{
              className: "breadcrumb-dark breadcrumb-transparent ",
            }}
          >
            <Breadcrumb.Item>
              <FontAwesomeIcon icon={faHome} />
            </Breadcrumb.Item>
            <Breadcrumb.Item active>Contact Owner</Breadcrumb.Item>
          </Breadcrumb>
          <h4>Contact Owner</h4>
        </div>
      </div>
      <div>
        <form className={styles.ownerForm} onSubmit={handleSubmit(submitOwner)}>
          <div>
            <label htmlFor="zohoCustomerId" className={styles.ownerLabel}>
              Zoho Customer Id
            </label>
            <input
              type="number"
              className={styles.ownerInput}
              id="zohoCustomerId"
              {...register("zohoCustomerId")}
            />
            <p className={styles.formError}>
              {errors?.zohoCustomerId?.message}
            </p>
          </div>
          <div>
            <label htmlFor="ownerName" className={styles.ownerLabel}>
              Contact Owner Name
            </label>
            <input
              className={styles.ownerInput}
              id="ownerName"
              {...register("ownerName")}
              disabled={disableInput}
            />
          </div>
          <div>
            <label htmlFor="ownerEmail" className={styles.ownerLabel}>
              Contact Owner Email
            </label>
            <input
              className={styles.ownerInput}
              id="ownerEmail"
              {...register("ownerEmail")}
              disabled={disableInput}
            />
          </div>
          <div>
            <label htmlFor="ownerPhone" className={styles.ownerLabel}>
              Contact Owner Phone
            </label>
            <input
              type="number"
              className={styles.ownerInput}
              id="ownerPhone"
              {...register("ownerPhone")}
              disabled={disableInput}
            />
          </div>
          <div>
            <label htmlFor="ownerRole" className={styles.ownerLabel}>
              Contact Owner Role
            </label>
            <input
              className={styles.ownerInput}
              id="ownerRole"
              {...register("ownerRole")}
              disabled={disableInput}
            />
          </div>
          <div>
            <label htmlFor="subOwnerName" className={styles.ownerLabel}>
              Sub Owner Name
            </label>
            <input
              className={styles.ownerInput}
              id="subOwnerName"
              {...register("subOwnerName")}
              disabled={disableInput}
            />
          </div>
          <div>
            <label htmlFor="subOwnerEmail" className={styles.ownerLabel}>
              Sub Owner Email
            </label>
            <input
              className={styles.ownerInput}
              id="subOwnerEmail"
              {...register("subOwnerEmail")}
              disabled={disableInput}
            />
          </div>
          <div>
            <label htmlFor="subOwnerPhone" className={styles.ownerLabel}>
              Sub Owner Phone
            </label>
            <input
              type="number"
              className={styles.ownerInput}
              id="subOwnerPhone"
              {...register("subOwnerPhone")}
              disabled={disableInput}
            />
          </div>
          <div>
            <label htmlFor="subOwnerRole" className={styles.ownerLabel}>
              Sub Owner Role
            </label>
            <input
              className={styles.ownerInput}
              id="subOwnerRole"
              {...register("subOwnerRole")}
              disabled={disableInput}
            />
          </div>
          <p className={styles.submitBtn}>
            <Button type="submit" disabled={isLoading}>
              {isLoading ? "Submitting" : "Submit"}
            </Button>
          </p>
        </form>
      </div>
    </>
  );
};
