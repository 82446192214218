import React, { useEffect, useState } from "react";
import { Card, Table } from "react-bootstrap";
import { deleteProformaUser, editProformaUser } from "../../service/api";
import { MdDelete } from "react-icons/md";
import { FaEdit } from "react-icons/fa";
import styles from "./index.module.css"
import { useForm } from "react-hook-form";
import Select from "react-select"
import styles2 from "../../pages/proformaUser/addProforma.module.css"
import { IoIosClose } from "react-icons/io";

const ProformaUserTable = ({ data, getUsers }) => {
  const { register, formState: { errors }, reset, handleSubmit } = useForm()
  const [userData, setUserData] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const runOptions = [{
    label: "Internal",
    value: "Internal"
  },
  {
    label: "External",
    value: "External"
  }]
  const [selectedOptions, setSelectedOptions] = useState(runOptions[0])

  const setUser = async (item) => {
    setIsEdit(true);
    reset({
      inceptionDevFee: item?.inceptionDevFee,
      developerDevFee: item?.developerDevFee,
      itcPercent: item?.itcPercent,
      folderId: item?.folderId,
      userName: item.name,
      pin: item.pin,
    })
    if (item?.designation === "External") {
      setSelectedOptions({ value: "External", label: "External" })
    }
    else {
      setSelectedOptions({ value: "Internal", label: "Internal" })
    }

  };

  const deleteUser = async (id) => {
    try {
      const response = await deleteProformaUser(id);
      const data = response.data.data;
      console.log(data)
      getUsers()
    } catch (error) {
      console.log(error);
    }
  };

  const onSubmit = async (values) => {
    console.log(values)
    try {
      const payload = {
        name: values.userName,
        pin: parseInt(values.pin),
        designation: selectedOptions.label,
        folderId: values.folderId,
        inceptionDevFee: values.inceptionDevFee,
        developerDevFee: values.developerDevFee,
        itcPercent: values.itcPercent,
      }
      const response = await editProformaUser(payload);
      const data = response.data.data;
      setIsEdit(false)
      getUsers()
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    if (data.length > 0) {
      setUserData(data)
    }
  }, [data])

  return (
    <>
      <div className="scrollable-table-container2">
        <Card border="light" className="table-wrapper table-responsive shadow-sm">
          <Card.Body className="pt-0">
            <Table hover className="user-table align-items-center">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Pin</th>
                  <th>Designation</th>
                  <th>Inception Dev Fee %</th>
                  <th>Developer Dev Fee %</th>
                  <th>ITC %</th>
                  <th>FolderId</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {userData?.length <= 0 ? (
                  <tr>
                    <td className="text-center bg-grey" colSpan={100}>
                      No Records Found.
                    </td>
                  </tr>
                ) : (
                  userData.map((item) => {
                    return (
                      <tr key={item._id}>
                        <td>{item.name}</td>
                        <td>{item.pin}</td>
                        <td>{item.designation}</td>
                        <td>{item?.inceptionDevFee ?? "-"}</td>
                        <td>{item?.developerDevFee ?? "-"}</td>
                        <td>{item?.itcPercent ?? "-"}</td>
                        <td>{item?.folderId}</td>
                        <td>
                          <span><FaEdit className={styles.editButton} onClick={() => setUser(item)} /></span>
                          <span><MdDelete className={styles.deleteButton} onClick={() => deleteUser(item._id)} /></span>
                        </td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      </div>
      {
        isEdit &&
        <div className={styles.overlay}>
          <div className={styles.overlayContent}>
            <p className={styles.closeBtn} onClick={() => setIsEdit(false)} ><IoIosClose /></p>
            <form onSubmit={handleSubmit(onSubmit)} className={styles.outerFormContainer}>
              <p className={styles2.heading}>Update User</p>
              <div className={styles2.formContainer}>
                <div className={styles2.inputContainer}>
                  <label className={styles2.labelStyle}>User Name</label>
                  <input disabled {...register("userName")} className={styles2.inputBox} placeholder='Enter user name' type="text" />
                </div>
                <div className={styles2.inputContainer}>
                  <label className={styles2.labelStyle}>Pin</label>
                  <input {...register("pin")} className={styles2.inputBox} placeholder='Enter Pin' type="number" />
                </div>
                <div className={styles2.inputContainer}>
                  <label className={styles2.labelStyle}>Designation</label>
                  <Select
                    options={runOptions}
                    value={{ value: selectedOptions.value, label: selectedOptions.label }}
                    onChange={(selected) => setSelectedOptions(selected)}
                  />
                </div>
                {
                  selectedOptions.label == "External" && <>
                    <div className={styles2.inputContainer}>
                      <label className={styles2.labelStyle}>Inception Dev Fee %</label>
                      <input {...register("inceptionDevFee")} className={styles2.inputBox} placeholder='Enter Inception Dev Fee %' type="text" />
                    </div>
                    <div className={styles2.inputContainer}>
                      <label className={styles2.labelStyle}>Developer Dev Fee %</label>
                      <input {...register("developerDevFee")} className={styles2.inputBox} placeholder='Enter Developer Dev Fee %' type="text" />
                    </div>
                    <div className={styles2.inputContainer}>
                      <label className={styles2.labelStyle}> ITC %</label>
                      <input {...register("itcPercent")} className={styles2.inputBox} placeholder='Enter ITC %' type="text" />
                    </div>
                    <div className={styles2.inputContainer}>
                      <label className={styles2.labelStyle}>Destination Folder ID</label>
                      <input {...register("folderId")} className={styles2.inputBox} placeholder='Enter folder ID' type="text" />
                    </div>
                  </>
                }
              </div>
              <input className={styles2.submitButton} type="submit" />
            </form>
          </div></div>
      }
    </>
  );
};

export default ProformaUserTable;
